<template>
  <div class="secure-report container template-1">
    <header class="page-header container mx-lg">
      <h1>{{ translations.components['report-parameters'].tcStateAssociationSummaries }}</h1>
    </header>
    <section class="container bg-block">
      <h3>{{ reportName }}</h3>
      <h5>
        {{
          translations.components['report-parameters']
            .tcChooseTheZoneThenStateToViewTheStateReportsOrAreaToViewTheAreaReports
        }}
      </h5>
      <b-form-group class="form-element">
        <div class="form_label bold">
          <strong>{{ translations.components['report-parameters'].tcZone }}</strong>
        </div>
        <b-form-select class="form-control g-select w-full" :options="zones" v-model="zone.org_key" :plain="true"
          @change="zoneChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label bold">
          <strong>{{ translations.components['report-parameters'].tcState2 }}</strong>
        </div>
        <b-form-select class="form-control g-select w-full" :options="states" v-model="state.org_key" :plain="true"
          @change="stateChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group>
        <div class="form_label">
          <strong>{{ translations.components['report-parameters'].tcStateReports }}</strong>
        </div>
        <b-form-select v-model="stateReport.key" :plain="true" @change="stateReportChange($event)">
          <option selected="selected" value="">
            {{ translations.components['report-parameters'].tcSelectAReport }}
          </option>
          <option value="UZO">
            {{ translations.components['report-parameters'].tcChurchAndGideonCardProgramByState }}
          </option>
          <option value="SAC">
            {{ translations.components['report-parameters'].tcChurchAndGideonCardProgramByArea }}
          </option>
          <option value="SSC">
            {{ translations.components['report-parameters'].tcChurchAndGideonCardProgramByCamp }}
          </option>
          <option value="SAM">{{ translations.components['report-parameters'].tcMembershipByArea }}</option>
          <option value="SSM">{{ translations.components['report-parameters'].tcMembershipByCamp }}</option>
          <option value="SAP">{{ translations.components['report-parameters'].tcScripturePlacementsByArea }}</option>
          <option value="SSP">{{ translations.components['report-parameters'].tcScripturePlacementsByCamp }}</option>
          <option value="SAR">{{ translations.components['report-parameters'].tcScriptureReceiptsByArea }}</option>
          <option value="SSR">{{ translations.components['report-parameters'].tcScriptureReceiptsByCamp }}</option>
          <option value="SAS">{{ translations.components['report-parameters'].tcStateAssociationSummary }}</option>
          <option value="STA">
            {{ translations.components['report-parameters'].tcStateAssociationSummariesCombined }}
          </option>
          <option value="SAX">
            {{ translations.components['report-parameters'].tcStateAssociationSummariesAuxiliary }}
          </option>
        </b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label">
          <strong>{{ translations.components['report-parameters'].tcYear }}</strong>
        </div>
        <b-form-select class="form-control g-select w-full" :options="stateYears" :plain="true" v-model="stateYear"
          @change="stateYearChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div v-for="(stateReportDetail, index) in this.stateUrls" :key="`g${index}`">
          <b-link target="_blank" :href="stateReportDetail.value"><strong>{{ stateReportDetail.text }}</strong></b-link>
        </div>
      </b-form-group>
      <b-form-group class="form-element mt-5">
        <div class="form_label">
          <strong>{{ translations.components['report-parameters'].tcArea }}</strong>
        </div>
        <b-form-select class="form-control g-select w-full" :options="areas" v-model="area.org_key"
          @change="areaReportChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group>
        <div class="form_label">
          <strong>{{ translations.components['report-parameters'].tcAreaReports }}</strong>
        </div>
        <b-form-select v-model="areaReport.key" :plain="true" @change="areaReportChange($event)">
          <option selected="selected" value="">
            {{ translations.components['report-parameters'].tcSelectAReport }}
          </option>
          <option value="ADS">{{ translations.components['report-parameters'].tcAreaDirectorSummaries }}</option>
          <option value="ADC">
            {{ translations.components['report-parameters'].tcAreaDirectorSummariesCombined }}
          </option>
          <option value="AAX">
            {{ translations.components['report-parameters'].tcAreaDirectorSummariesAuxiliary }}
          </option>
        </b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label">
          <strong>{{ translations.components['report-parameters'].tcYear }}</strong>
        </div>
        <b-form-select class="form-control g-select w-full" :options="areaYears" :plain="true" v-model="areaYear"
          @change="areaYearChange($event)"></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div v-for="(areaReportDetail, index) in this.areaUrls" :key="`g${index}`">
          <b-link target="_blank" :href="areaReportDetail.value"><strong>{{ areaReportDetail.text }}</strong></b-link>
        </div>
      </b-form-group>
      <b-form-group>
        <b-button class="btn btn-tertiary btn-w-med ml-4" size="sm" id="btnCancelForm" @click="handleCancelClick">{{
          translations.components['report-parameters'].tcCancel
        }}</b-button>
      </b-form-group>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { empty_guid } from '@/json/data.json'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'state-association-summaries',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'report-parameters': [],
        },
      },
      title: 'State Association Summaries',
      values: {},
      reportName: '',
      reportParamParentKey: '',
      zones: [],
      states: [],
      stateYears: [],
      stateUrls: null,
      zone: {
        lvl_code: null,
        org_key: null,
        org_name: null,
      },
      state: {
        lvl_code: null,
        org_key: null,
        org_name: null,
      },
      stateReport: {
        key: null,
        value: null,
      },
      stateYear: null,
      reportStateYearsData: {
        zone: null,
        state: null,
        stateReport: null,
      },
      reportStateUrlsData: {
        zone: null,
        state: null,
        stateReport: null,
        stateYear: null,
      },
      areas: [],
      areaYears: [],
      areaUrls: null,
      area: {
        lvl_code: null,
        org_key: null,
        org_name: null,
      },
      areaReport: {
        key: null,
        value: null,
      },
      areaYear: null,
      reportAreaYearsData: {
        area: null,
        areaReport: null,
      },
      reportAreaUrlsData: {
        area: null,
        areaReport: null,
        areaYear: null,
      },
    }
  },
  methods: {
    ...mapActions({
      getAreas: 'secureReport/getAreas',
      getAreaReportUrls: 'secureReport/getAreaReportUrls',
      getAreaReportYears: 'secureReport/getAreaReportYears',
      getStates: 'secureReport/getStates',
      getStateReportUrls: 'secureReport/getStateReportUrls',
      getStateReportYears: 'secureReport/getStateReportYears',
      setLoadingStatus: 'menu/setLoadingStatus',
      getZones: 'secureReport/getZones',
    }),
    async page_load() {
      this.setLoadingStatus(true)
      await this.getZones()
      this.zones = this.zoneDetails.map((item) => {
        return { text: item.org_name, value: item.org_key }
      })
      this.zones.unshift({
        text: this.translations.components['report-parameters'].tcSelectAZone,
        value: empty_guid,
      })
      this.zone.org_key = empty_guid
      this.state.org_key = empty_guid
      this.stateReport.key = ''
      this.stateYear = ''
      this.stateUrls = null

      this.area.org_key = empty_guid
      this.areaReport.key = ''
      this.areaYear = ''
      this.setLoadingStatus(false)
    },
    async zoneChange(evt) {
      this.setLoadingStatus(true)
      this.zoneChangeReset()
      await this.getStates(this.zone.org_key)
      this.states = this.stateDetails.map((item) => {
        return { text: item.org_name, value: item.org_key }
      })
      this.states.unshift({
        text: this.translations.components['report-parameters'].tcSelectAState,
        value: empty_guid,
      })

      this.setLoadingStatus(false)
    },
    async stateChange(evt) {
      this.setLoadingStatus(true)
      this.stateChangeReset()
      await this.getAreas(this.state.org_key)
      this.areas = this.areaDetails.map((item) => {
        return { text: item.org_name, value: item.org_key }
      })
      this.areas.unshift({
        text: this.translations.components['report-parameters'].tcSelectAnArea,
        value: empty_guid,
      })
      this.area.org_key = empty_guid
      this.setLoadingStatus(false)
    },
    async stateReportChange(evt) {
      if (!this.zone.org_key || !this.state.org_key || !this.stateReport.key) return false
      this.setLoadingStatus(true)
      this.reportStateYearsData.zone = this.zoneDetails.find((x) => x.org_key === this.zone.org_key).lvl_code
      this.reportStateYearsData.state = this.stateDetails.find((x) => x.org_key === this.state.org_key).lvl_code
      this.reportStateYearsData.stateReport = this.stateReport.key
      await this.getStateReportYears(this.reportStateYearsData)
      this.stateYears = this.stateYearDetails.map((item) => {
        return { text: item, value: item }
      })
      this.stateYears.unshift({ text: this.translations.components['report-parameters'].tcSelectAYear, value: '' })
      this.stateUrls = null
      if (this.stateYear !== '') {
        await this.stateYearChange()
      }
      this.setLoadingStatus(false)
    },
    async stateYearChange(evt) {
      if (!this.zone.org_key || !this.state.org_key || !this.stateReport.key || !this.stateYear) return false

      this.setLoadingStatus(true)
      this.reportStateUrlsData.zone = this.zoneDetails.find((x) => x.org_key === this.zone.org_key).lvl_code
      this.reportStateUrlsData.state = this.stateDetails.find((x) => x.org_key === this.state.org_key).lvl_code
      this.reportStateUrlsData.stateReport = this.stateReport.key
      this.reportStateUrlsData.stateYear = this.stateYear

      await this.getStateReportUrls(this.reportStateUrlsData)
      this.stateUrls = this.stateReportDetails.map((item) => {
        var objDate = new Date()
        objDate.setDate(1)
        objDate.setMonth(item.Month - 1)
        var month = objDate.toLocaleString(this.prefCulture, { month: 'long' })
        if (item.URL.includes('.csv')) {
          return { text: month + ' ' + item.Year + ' - ' + this.translations.components['report-parameters'].tcData, value: item.URL }
        }
        else {
          return { text: month + ' ' + item.Year, value: item.URL }
        }

      })
      this.setLoadingStatus(false)
    },
    async areaReportChange(evt) {
      if (
        !this.zone.org_key ||
        !this.state.org_key ||
        !this.area.org_key ||
        !this.areaReport.key ||
        this.state.org_key === empty_guid
      )
        return false

      this.setLoadingStatus(true)
      this.reportAreaYearsData.zone = this.zoneDetails.find((x) => x.org_key === this.zone.org_key).lvl_code
      this.reportAreaYearsData.state = this.stateDetails.find((x) => x.org_key === this.state.org_key).lvl_code
      this.reportAreaYearsData.area = this.areaDetails.find((x) => x.org_key === this.area.org_key).lvl_code
      this.reportAreaYearsData.areaReport = this.areaReport.key
      await this.getAreaReportYears(this.reportAreaYearsData)
      this.areaUrls = null
      this.areaYears = this.areaYearDetails.map((item) => {
        return { text: item, value: item }
      })
      this.areaYears.unshift({
        text: this.translations.components['report-parameters'].tcSelectAYear,
        value: ''
      })
      if (this.areaYear !== '') {
        await this.areaYearChange()
      }
      this.setLoadingStatus(false)
    },
    async areaYearChange(evt) {
      if (!this.zone.org_key || !this.state.org_key || !this.area.org_key || !this.areaReport.key || !this.areaYear)
        return false
      this.setLoadingStatus(true)
      this.reportAreaUrlsData.zone = this.zoneDetails.find((x) => x.org_key === this.zone.org_key).lvl_code
      this.reportAreaUrlsData.state = this.stateDetails.find((x) => x.org_key === this.state.org_key).lvl_code
      this.reportAreaUrlsData.area = this.areaDetails.find((x) => x.org_key === this.area.org_key).lvl_code
      this.reportAreaUrlsData.areaReport = this.areaReport.key
      this.reportAreaUrlsData.areaYear = this.areaYear

      await this.getAreaReportUrls(this.reportAreaUrlsData)
      this.areaUrls = this.areaReportDetails.map((item) => {
        var objDate = new Date()
        objDate.setDate(1)
        objDate.setMonth(item.Month - 1)
        var month = objDate.toLocaleString(this.prefCulture, { month: 'long' })
        return { text: month + ' ' + item.Year, value: item.URL }
      })
      this.setLoadingStatus(false)
    },
    handleCancelClick() {
      this.$router.push({ path: `/reports/report-center` })
    },
    zoneChangeReset() {
      this.state = {
        lvl_code: null,
        org_key: empty_guid,
        org_name: null,
      }
      this.stateChangeReset()
    },
    stateChangeReset() {
      this.stateReport = {
        key: '',
        value: null,
      }
      this.stateYear = ''
      this.stateUrls = ''

      this.areas = [
        {
          text: this.translations.components['report-parameters'].tcSelectAnArea,
          value: empty_guid,
        },
      ]
      this.area = {
        lvl_code: null,
        org_key: empty_guid,
        org_name: null,
      }
      this.areaReport = {
        key: '',
        value: null,
      }
      this.areaYear = ''
      this.areaUrls = ''
    },
    setStateYear() {
      this.stateYears.unshift({
        text: this.translations.components['report-parameters'].tcSelectAYear,
        value: '',
      })
    },
    setAreaYear() {
      this.areaYears.push({
        text: this.translations.components['report-parameters'].tcSelectAYear,
        value: '',
      })
    },
    setStates() {
      this.states.unshift({
        text: this.translations.components['report-parameters'].tcSelectAState,
        value: empty_guid,
      })
    },
    setArea() {
      this.areas.unshift({
        text: this.translations.components['report-parameters'].tcSelectAnArea,
        value: empty_guid
      })
    },
  },
  async created() {
    await this.getPseudoComponentTranslations('report-parameters').then((results) => {
      const pseudoComponentTranslations = results
      this.$set(this.translations, 'components', pseudoComponentTranslations)
      this.setStateYear()
      this.setAreaYear()
      this.setStates()
      this.setArea()
    })
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      areaDetails: 'secureReport/areas',
      areaReportDetails: 'secureReport/areaReportUrls',
      areaYearDetails: 'secureReport/areaReportYears',
      demograph: 'user/demograph',
      prefCulture: 'user/userPreferredCulture',
      reportParentKey: 'reportParameter/reportParentKey',
      selectedReportKey: 'reportParameter/selectedReportKey',
      stateDetails: 'secureReport/states',
      stateReportDetails: 'secureReport/stateReportUrls',
      stateYearDetails: 'secureReport/stateReportYears',
      userCamp: 'user/userCamp',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      zoneDetails: 'secureReport/zones',
    }),
  },
  components: {},
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

select {
  height: auto !important;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  max-width: 300px;
}

input[type='date'] {
  border: 1px solid #636363;
  padding: 13px 20px 13px 60px;
  height: auto;
  font-weight: bold;
  font-size: 16px;
}

input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.rpt-checkbox {
  font-weight: bolder;
  margin: 30px 15px 10px 0px;
}

.rpt-label {
  font-weight: bolder;
  margin-top: 30px;
}

.rpt-checkbox+div {
  display: inline-block;
}

div .report-download-btn {
  margin: 30pt 0px 10px 0px;
}
</style>
